import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { Square } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

import WrapperTextComponent from '@/components/WrapperTextComponent.tsx'

type BulletListProps = {
  items: string[]
} & QaHook

export const BulletList = (props: BulletListProps) => {
  const { items, dataCy } = props

  return (
    <List data-cy={dataCy} disablePadding sx={{ border: '0px' }}>
      {items?.map((item, index) => (
        <ListItem
          key={item}
          disableGutters
          dense
          sx={{ py: '2px', alignItems: 'baseline' }}
          data-cy={`${dataCy}-item-${index}`}
        >
          <ListItemIcon sx={{ minWidth: 16, verticalAlign: '' }}>
            <Square color="secondary" sx={{ width: 8, height: 8 }} />
          </ListItemIcon>
          <ListItemText
            sx={{ my: 0 }}
            disableTypography // otherwise ct-design styles are not applied to WrapperTextComponent
            primary={
              <WrapperTextComponent
                Component={Typography}
                content={item}
                variant="body2dense"
              />
            }
          />
        </ListItem>
      ))}
    </List>
  )
}
